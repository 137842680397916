import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOrganizationsList() {
  // Use toast
  const toast = useToast()

  const refReportDataTable = ref(null)

  // Table Handlers
  const tableColumns = ref(null)
  const perPage = ref(10)
  const totalOrganizations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const reportNameOptions = ref([])
  const reportFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refReportDataTable.value ? refReportDataTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrganizations.value,
    }
  })

  const refetchData = () => {
    refReportDataTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, reportFilter], () => {
    console.log("getReportFilter",  reportFilter)
    refetchData()
  })

  const fetchReportNames = (ctx, callback) => {
    store
      .dispatch('app-report/fetchReportNames', {})
      .then(response => {
        // const { reports, total } = response.data
        reportNameOptions.value = response.data.response
        // const total = 10
        // const reports = reports.map(x => (x.status === 'active') ? x.statusText = 'Идэвхитэй' : (x.status === 'inactive') ? x.statusText = 'Идэвхигүй' : x.statusText = 'Хүлээгдэж буй')
        // callback(reports)
        // totalOrganizations.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching report data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchReportData = (ctx, callback) => {
    console.log('1111111111111111111', reportFilter)
    store
      .dispatch('app-report/fetchReportData', {
        "report_name": reportFilter.value.report_name,
        "params": [
          reportFilter.value.start_date, 
          reportFilter.value.end_date
        ]
    })
      .then(response => {
        // const { reports, total } = response.data
        const reports = response.data.response.reportData
        tableColumns.value = response.data.response.reportHeader

        const total = 10
        // const reports = reports.map(x => (x.status === 'active') ? x.statusText = 'Идэвхитэй' : (x.status === 'inactive') ? x.statusText = 'Идэвхигүй' : x.statusText = 'Хүлээгдэж буй')
        callback(reports)
        // totalOrganizations.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching report data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveOrganizationRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveOrganizationRoleIcon = role => {
    if (role === 'subscriber') return 'OrganizationIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'OrganizationIcon'
  }

  const resolveOrganizationStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }


  return {
    fetchReportNames,
    fetchReportData,
    tableColumns,
    perPage,
    currentPage,
    totalOrganizations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReportDataTable,
    reportNameOptions,

    resolveOrganizationRoleVariant,
    resolveOrganizationRoleIcon,
    resolveOrganizationStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    reportFilter
  }
}
