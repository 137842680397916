<template>

  <div>

    <!-- <organization-list-add-new
      :is-add-new-organization-sidebar-active.sync="isAddNewOrganizationSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <!-- <organizations-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->
    <reports-list-filters
      :report-filter.sync="reportFilter"
      :report-name-options="reportNameOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Нэг хуудсанд</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> бичлэг харуулах</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Хайх..."
              />
              <b-button
                variant="primary"
                @click="exportToExcel()"
              >
                <span class="text-nowrap">Экспорт</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        v-if="reportNameOptions.length > 0"
        ref="refReportDataTable"
        class="position-relative"
        :items="fetchReportData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Organization -->
        <template #cell(organization)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveOrganizationRoleVariant(data.item.role)}`"
                :to="{ name: 'organizations-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'organizations-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.organizationname }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveOrganizationRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveOrganizationRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveOrganizationStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'organizations-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Дэлгэрэнгүй</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'organizations-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Засах</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Устгах</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Нийт {{ dataMeta.of }}-н {{ dataMeta.from }} - {{ dataMeta.to }} бичлэгийг  харуулж байна</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrganizations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ReportsListFilters from './ReportsListFilters.vue'
import useReportsList from './useReportsList'
import reportStoreModule from '../reportStoreModule'
import OrganizationListAddNew from './OrganizationListAddNew.vue'
import { parseTime } from '@/utils'
import parse from 'uuid/dist/parse'

export default {
  components: {
    ReportsListFilters,
    OrganizationListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const REPORT_APP_STORE_MODULE_NAME = 'app-report'

    // Register module
    if (!store.hasModule(REPORT_APP_STORE_MODULE_NAME)) store.registerModule(REPORT_APP_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP_STORE_MODULE_NAME)) store.unregisterModule(REPORT_APP_STORE_MODULE_NAME)
    })

    const isAddNewOrganizationSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Админ', value: 'admin' },
      { label: 'Эрхлэгч', value: 'author' },
      { label: 'Засварлагч', value: 'editor' },
      { label: 'Дэмжигч', value: 'maintainer' },
      { label: 'Дагагч', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Үндсэн', value: 'basic' },
      { label: 'Байгууллага', value: 'company1' },
      { label: 'Аж ахуй нэгж', value: 'enterprise' },
      { label: 'Баг', value: 'team' },
    ]

    const statusOptions = [
      // { label: 'Хүлээгдэж буй', value: 'pending' },
      { label: 'Идэвхитэй', value: 'true' },
      { label: 'Идэвхигүй', value: 'false' },
    ]

    const {
      fetchReportNames,
      fetchReportData,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReportDataTable,
      refetchData,

      // UI
      resolveOrganizationRoleVariant,
      resolveOrganizationRoleIcon,
      resolveOrganizationStatusVariant,

      reportNameOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      reportFilter
    } = useReportsList()

    fetchReportNames()

    const exportToExcel = () => {
      store
        .dispatch('app-report/fetchReportData', {
          "report_name": reportFilter.value.report_name,
          "params": [
            reportFilter.value.start_date, 
            reportFilter.value.end_date
          ]
      })
        .then(response => {
           import('@/vendor/Export2Excel').then(excel => {
              excel.export_json_to_excel({
                header: response.data.response.reportHeader,
                data: response.data.response.reportData.map(x => response.data.response.reportHeader.map(y => { 
                  if (y.includes("_at")) {
                    return parseTime(x[y])
                  } 
                  // else if (y.includes("_date")) {
                  //   return parseTime(x[y])
                  // } 
                  // else if (y.includes("_time")) {
                  //   return parseTime(x[y])
                  // }
                  else {
                    return x[y]
                  }
                })),
                filename: 'ticket-' + reportFilter.value.start_date + "-" + reportFilter.value.end_date,
                autoWidth: true, //Optional
                bookType: 'csv' //Optional
              })
            })
        })
        .catch((error) => {
          console.log(error)
        })
    }

    return {

      // Sidebar
      isAddNewOrganizationSidebarActive,

      fetchReportNames,
      fetchReportData,
      tableColumns,
      perPage,
      currentPage,
      totalOrganizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReportDataTable,
      refetchData,
      exportToExcel,

      // Filter
      avatarText,

      // UI
      resolveOrganizationRoleVariant,
      resolveOrganizationRoleIcon,
      resolveOrganizationStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,
      reportNameOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      reportFilter
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
